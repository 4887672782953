import React, { useRef, useState } from 'react';
import '../styles/BookAppointment.css';
import arrow from '../../images/home/arrow.png';
import emailjs from '@emailjs/browser';
import Popup from '../../shared/Popup';
import ReCAPTCHA from "react-google-recaptcha";

function BookAppointmentComponent() {

    const [buttonPopup, setButtonPopup] = useState(false);
    const [capVal, setCapVal] = useState(null);
    const recaptchaRef = useRef(null);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_7rdbwzn', 'template_3rfgk1o', e.target, 'user_aAx63STK8JFSdqbKPIGOv')
            .then((result) => {
                console.log(result.text);
                setButtonPopup(true);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        // Reset reCAPTCHA
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }

    return (
        <div style={{ paddingTop: '15vh', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <div style={{ textAlign: 'center', color: '#143142', fontSize: 40, fontFamily: 'Rubik', fontWeight: '500' }}>REQUEST APPOINTMENT</div>
            <div className='bookAppointmentFormContainer'>
                <div style={{ color: '#244455', fontSize: 16, fontFamily: 'Karla', fontWeight: '500' }}>Complete the form below, and our team will be in touch with you shortly.</div>
                <br />
                <form onSubmit={sendEmail} id="form1">
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}>First Name <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="firstName input-text input-row1"
                                type="text"
                                name="First"
                                required
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Last Name </label>
                            <input
                                className="lastName input-text input-row1"
                                type="text"
                                name="Last"
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Email Id <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="Email input-text"
                                type="email"
                                name="Email"
                                required
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Phone Number <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="phno input-text"
                                type="tel"
                                name="Phone"
                                required
                                style={{ width: '280px', opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                            />
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ color: 'black', fontFamily: 'Karla', fontSize: 14 }}> Inquiry <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                        <textarea
                            className="message input-text"
                            type="text"
                            name="Message"
                            required
                            style={{ opacity: 0.90, background: 'rgba(248, 248, 248, 0.30)', borderRadius: 5, border: '1px #CBCBCB solid', color: 'black', padding: '1%' }}
                        />
                    </div>
                    <br />
                    <div>
                        <h4 style={{ color: 'black' }}>How did you hear about us?</h4>
                        <input type="radio" id="google" name="Howknow" value="Web search (eg.- Google)" />
                        <label for="google" style={{ color: 'black', paddingLeft: '3%' }}>Web search (eg.- Google)</label><br />
                        <input type="radio" id="friend" name="Howknow" value="Referred by a friend" />
                        <label for="friend" style={{ color: 'black', paddingLeft: '3%' }}>Referred by a friend</label><br />
                        <input type="radio" id="flyer" name="Howknow" value="Flyer" />
                        <label for="flyer" style={{ color: 'black', paddingLeft: '3%' }}>Flyer</label><br />
                        <input type="radio" id="event" name="Howknow" value="Event" />
                        <label for="event" style={{ color: 'black', paddingLeft: '3%' }}>Event</label><br />
                        <input type="radio" id="promotion" name="Howknow" value="ImPerfect Promotion" />
                        <label for="promotion" style={{ color: 'black', paddingLeft: '3%' }}>ImPerfect Promotion</label>
                    </div>
                    <br />
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey="6LciBywpAAAAADaQ-LhY931ukbQ6yD4KaiNVfZ0x"
                        onChange={(val) => setCapVal(val)}
                    />
                    <br />
                    <button disabled={!capVal} style={{ background: '#143141', borderRadius: 24, display: 'flex', alignItems: 'center', width: '150px', color: 'white', justifyContent: 'center' }} className="btn" type='submit'>
                        Submit
                        <img src={arrow} style={{ height: 'auto', width: '14px', marginLeft: '5%' }} alt='arrow' />
                    </button>
                    {/* <button style={{ background: '#143141', borderRadius: 24, color: 'white', padding: '1% 5%' }} type="submit" className="btn">Submit <span><img src={arrow} style={{ height: 'auto', width: '14px' }} alt='arrow' /></span></button> */}
                </form>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <h3>Message sent successfully!</h3>
                <p>Team Aethos will get back to you soon.</p>
            </Popup>
        </div>
    )
}

export default BookAppointmentComponent