import Josh from "../../images/team/josh.png";
import Emma from "../../images/team/emma.png";
import Adam from "../../images/team/adam.png";
import Ama from "../../images/team/ama.png";
import Tori from "../../images/team/tori.png";
import Veronica from "../../images/team/veronica.png";
import Sophia from "../../images/team/sophia.png";
import Jon from "../../images/team/jon.png";
// import Brandon from "../../images/team/brandon.png";
import Milad from "../../images/team/milad.png";
import Kennedy from "../../images/team/kennedy.png";
import Kyle from "../../images/team/kyle.png";
import Roville from "../../images/team/roville.png";
import Chris from "../../images/team/chris.png";


export const teamCards = [
  {
    name: "Dr. Josh Binstock",
    title: "Director of Clinical Operations",
    img: Josh,
    index: 1,
    description: "Josh is a two-time volleyball Olympian, having represented Canada at the 2012 London and 2016 Rio Games. Additionally, he represented Canada at the 2015 Toronto Pan-American Games.\nHe is in charge of managing Aethos' clinical department. He leads our clinical staff by planning, coordinating and overseeing the delivery of patient care. Additionally, is responsible for monitoring and ensuring the highest quality of service is administered. His goal is to ensure the smooth running of the clinical department aiming to maximum performance, while also acting as a connection between patients, their families and our clinical staff."
  },
  {
    name: "Emma Dolphin",
    title: "Director of Operations",
    img: Emma,
    index: 2,
    description: "Emma is an experienced personal training coach and retired elite level athlete. She was awarded the female athlete of the year at Duke University and represented her country on the Canadian National Rowing team. She is a two-time World's Medalist and was awarded Female Crew of the Year.",
  },
  {
    name: "Dr. Tori Cowley",
    title: "Naturopathic Doctor",
    img: Tori,
    index: 3,
    description: 'Dr. Tori Cowley graduated from Wilfrid Laurier University, where she received her Bachelor of Science degree in Kinesiology. She went on to pursue her Medical degree from The Canadian College of Naturopathic Medicine. Dr. Tori travels internationally representing Canada in beach volleyball on the International World Tour.',
  },
  {
    name: "Veronica Manco",
    title: "Office Manager, Registered Nurse",
    img: Veronica,
    index: 4,
    description: "Veronica graduated with honours from Humber College in 2003 with a diploma in Nursing. She worked as a surgical nurse in Dentistry and Oral surgery for 15 years. She has always loved the idea of fitness and wellness all under one roof and with a history in health and administration, she enjoys being able to combine that knowledge in her role at Aethos. In her spare time she spends time with her family, enjoys organizing, art & design, and hiking.",
  },
  {
    name: "Sophia Zheng",
    title: "Registered Dietitian & CDE",
    img: Sophia,
    index: 5,
    description: "Sophia graduated from McGill University, and is a member of Dietitians of Canada, College of Dietitians of Ontario, and Diabetes Canada. Through years of practice, Sophia has specialized in weight loss, chronic disease prevention and management, pregnancy and breastfeeding nutrition and preconception health. Everyone's metabolism and lifestyle is different, so there is no one diet that works for all. That is why Sophia focuses on providing a personalized experience. She will take time to carefully listen to you, and provide the most pertinent yet realistic suggestions. Throughout this journey, you will work towards a meal and exercise plan that will gradually become your habit, making your investment last a lifetime. Besides operating a private practice, she also collaborates with top epigenetic and reproductive researchers from McGill University, CReATe Fertility Centre and Health Canada, studying the effects of father's diet and nutritional status on their reproductive health. She presented preliminary findings at the Canadian Fertility & Andrology Society's (CFAS) annual meeting.",
  },
  {
    name: "Ama Kelsy",
    title: "Front Desk Manager",
    img: Ama,
    index: 6,
    description: "Ama spent most of her childhood and teenagehood playing any sport you could think of, though, ironically, she went to film school and majored in screenwriting. In her spare time, she loves watching horror movies (specifically, gothic-horror) and writing short horror and science fiction stories and articles. Ama is your go-to for all your scheduling and booking needs!",
  },
  {
    name: "Adam Vandersluis",
    title: "Personal Trainer",
    img: Adam,
    index: 7,
    description: "Adam has been active his entire life. Growing up he regularly went on camping and hiking trips with his family and weekend were filled with hockey or soccer tournaments. He studied Kinesiology at Western University and earned my 4 year Bachelors degree with honours. Since then he has completed his Personal trainer, Group fitness, Nutritionist and Strength and conditioning certifications. Adam finds a lot of motivation seeing his clients progress to overcome obstacles they might not be able to face alone. His training style combines functional strength with coordinated movement, as well as a focus on the positives experienced throughout our workouts.",
  },
  {
    name: "Jon Ho",
    title: "Personal Trainer",
    img: Jon,
    index: 8,
    description: "Jonathan graduated from the University of Waterloo with a Bachelor of Science in Kinesiology with a Specialization in Rehabilitation Sciences. He is currently pursuing his Doctor of Chiropractic at the Canadian Memorial Chiropractic College. Jonathan has worked with a variety of populations in a multitude of fitness settings including: Varsity Strength and Conditioning Coach & Personal Trainer for the University of Waterloo, Group Fitness Instructor for Orangetheory Fitness, and Physical Training Instructor at the Ontario Police College. Jonathan's goal is to use his knowledge of exercise physiology, biomechanics, ergonomics, injury prevention, and corrective exercise to help each client to optimally achieve their goals in the safest way possible.",
  },
  // {
  //   name: "Brandon Chang",
  //   title: "Resident Physiotherapist",
  //   img: Brandon,
  //   index: 9,
  //   description: "Brandon is deeply passionate about helping others resolve acute or chronic musculoskeletal injuries that may limit their physical performance and quality of daily life. His goal is to help others strive towards developing physical resiliency through injury management and prevention, with a strong focus on education. \nBrandon graduated from the University of Waterloo with a Bachelor of Science degree in Kinesiology in 2017 and went on to complete his Master of Physiotherapy degree at the University of Sydney, graduating in 2021. \nAs a strength and conditioning coach, he possesses an extensive background in sports and athletic development, having worked with various varsity level sports teams and athletes, and has been immersed in the world of sport since the age of 4. He grew up in Markham playing AAA/AA ice hockey in the OMHA and GTHL, along with competitive soccer and taekwondo. \nBrandon is dedicated to using evidence-based practice, treatments, and strategies to help individuals managed their injuries and achieve their greatest potential through movement and a healthy lifestyle.",
  // },
  {
    name: "Milad Ghasemitabar",
    title: "Physiotherapist",
    img: Milad,
    index: 10,
    description: "Milad graduated from York University in Toronto with a Bachelor of Kinesiology, specialized honour. He later received his Doctor of Physiotherapy degree from D'Youville University in Buffalo, New York. His lifelong interest in anatomy, love and appreciation for exercise and a desire to help people to improve their quality of life led him to pursue a career in Physiotherapy. He uses various manual therapy techniques combined with therapeutic exercises and patient education to help patients achieve their short and long term goals. Milad loves traveling and he is an aviation enthusiast and during downtime he enjoys reading and working out.",
  },
  {
    name: "Dr. Kennedy Hindley",
    title: "Chiropractor",
    img: Kennedy,
    index: 11,
    description: "Kennedy Graduated with his Doctor of Chiropractic at the Canadian Memorial Chiropractic College (CMCC). Kennedy fills various performance roles at Aethos, including active rehabilitation and strength and conditioning. He graduated from UBC with his Bachelor of Human Kinetics and was a member of the varsity men's soccer team. Kennedy is a life-long athlete competing in a variety of sports, and also holds provincial powerlifting records.",
  },
  {
    name: "Dr. Kyle McCasey",
    title: "Chiropractor",
    img: Kyle,
    index: 12,
    description: "Dr. McCasey graduated from Queen's University and went on to earn her Doctorate of Chiropractic at the Canadian Memorial Chiropractic College (CMCC), where she graduated summa cum laude. She is also certified in Contemporary Medical Acupuncture, Active Release Therapy (ART), and Foundations in Functional Assessment. Dr. McCasey has been involved in competitive sports her whole life.  She competed as a member of the Queen's University Women's Rowing team and swam competitively for eleven years and currently maintains an active lifestyle.",
  },
  {
    name: "Roville Daniels",
    title: "Registered Massage & Athletic Therapist",
    img: Roville,
    index: 13,
    description: "Roville is a Registered Massage Therapist, Athletic Therapist, Certified Strength Coach and a Neurofunctional Contemporary Medical Acupuncture Provider. \nRoville earned a Bachelor of Health Science from UOIT and then received a Graduate Certificate in Athletic Therapy from York University; He later completed an Advanced Standing Massage Therapy diploma at the Ontario College of Health and Technology. \nRoville takes on an integrated approach when assessing and treating patients using various orthopedic, Neurofunctional tests to breakdown the problem areas, and then using various manual techniques, mobilizations, corrective exercise, cupping and acupuncture. Ultimately, with the goal of helping his patients live a long healthy and athletic life. \nRoville has many years of experience working in varsity collegiate sport as well as working with national and professional athletes associated with Baseball Canada, Rugby Ontario, Junior A Hockey and Cricket Canada. With his background in sports medicine, he has plenty of experience in post-operative rehab, concussion assessment and rehab and the treatment of both acute and chronic pain. \nStriving for balance in his own life Roville understands the importance of being able to live your best life and being resilient from stress. Regardless of why you walk in through those doors Roville will always look into the best treatment plan to allow you to continue living the life that you love.",
  },
  {
    name: "Chris Frattasi",
    title: "Personal Trainer & Fascial Stretch Therapist",
    img: Chris,
    index: 14,
    description: "Chris is a dedicated and passionate Personal Trainer and Fascial Stretch Therapist (FST) with a background in Kinesiology from York University. He was originally drawn to sports focused training and building customized programming for athletes, however Chris discovered a genuine joy in helping individuals of all backgrounds. His three fundamental pillars in his training practice center around promoting health and wellness practices, optimizing performance, and enhancing longevity. \nAs an FST practitioner, Chris sees the synergy between FST, training and overall health and fitness. This pain free manual therapy, similar to massage, enhances flexibility, improves joint mobility and restores posture by targeting the fascial lines within the body. FST goes beyond athletic enhancement as it can also address chronic pain and enhance movement quality. Whether you're an athlete seeking peak performance or someone dealing with persistent discomfort, FST can be a holistic key to unlocking your body's potential.",
  },
];
