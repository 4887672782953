import banner from '../../images/banner.webp';
import AethosHome from '../../images/home/AethosHomenew.mp4';
import michaela from '../../images/home/michaela.webp';
import paul from '../../images/home/paul.webp';
import natasha from '../../images/home/natasha.webp';
import home_image from '../../images/home/aethos_home.webp';
import home_school_program from '../../images/home/home_school_program.webp';
import chiro_care from '../../images/home/chiro_care.webp';
import physio from '../../images/home/physio.webp';
import massage from '../../images/home/massage.webp';
import active_care from '../../images/home/active_care.webp';
import personal_training from '../../images/home/personal_training.webp';
import medicine from '../../images/home/medicine.webp';
import '../styles/Home.css';

function HomeComponent() {  
    return (
        <div style={{ color: 'black', paddingTop: '6vh' }}>
            <div className='headerBox mt-4 mb-4'>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <h1 style={{ color: '#2E2E2E', fontSize: 36, fontFamily: 'Open Sans, sans-serif', fontWeight: '700' }}>Toronto’s Leading Wellness and Health Optimization Center</h1>
                    <p className='text-break mb-4' style={{ color: '#7A7A7A', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '600' }}>Discover personalized health solutions tailored to your body and goals. Our expert team delivers comprehensive assessments, custom movement plans, and strategic goal-setting to elevate your wellness journey.</p>
                </div>
                <div className='text-center' style={{ width: '100%' }}>
                    <a className="btn" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 246, background: '#BC9437', color: '#FFFFFF', fontSize: 16, fontFamily: 'Open Sans, sans-serif', fontWeight: '600', borderRadius: 0 }} href='/request-appointment'>
                        Request appointment
                    </a>
                </div>
            </div>
            
            <img src={home_image} className='homeImg' alt="Bright and welcoming health center reception featuring contemporary interiors and natural light." />
            
            <div className='headerBox mt-4'>
                <div style={{ textAlign: 'center' }}>
                    <p style={{ color: '#2E2E2E', fontSize: 28, fontFamily: 'Open Sans, sans-serif', fontWeight: '700' }}>What we do</p>
                    <p className='text-break' style={{ color: '#7A7A7A', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '600' }}>At Aethos we to cater to your every fitness and wellness need.</p>
                </div>
                <div className="row mt-5">
                    <a className="col-lg-4 mb-2" style={{minHeight: '444px', textDecoration: 'none' }} href='/what-we-offer'>
                        <img src={chiro_care} alt="Chiropractor providing tailored spinal adjustment therapy to alleviate pain and restore mobility." className='servicesImg card-img-top' />
                        <div className="card-body mt-3 mb-1" style={{borderBottom: '1px solid #D2D2D2', height: '120px' }}>
                            <h2 style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 18, fontWeight: '700' }} >Chiropractic Care</h2>
                            <p style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400' }} >Restore mobility and alleviate pain through spinal adjustments tailored to your needs.</p>
                        </div>
                        <p style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 14, fontWeight: '600', textDecoration: 'none' }}>Learn More -<i className="arrow right"></i></p>
                    </a>
                    <a className="col-lg-4 mb-2" style={{minHeight: '444px', textDecoration: 'none' }} href='/what-we-offer'>
                        <img src={physio} alt="Physiotherapist helping a patient recover mobility with personalized therapy techniques." className='servicesImg card-img-top' />
                        <div className="card-body mt-3 mb-1" style={{borderBottom: '1px solid #D2D2D2', height: '110px' }}>
                            <h2 style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 18, fontWeight: '700' }} >Physiotherapy</h2>
                            <p style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400' }} >Improve movement and recovery with personalized therapy sessions designed to treat injuries and enhance strength.</p>
                        </div>
                        <a style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 14, fontWeight: '600', textDecoration: 'none' }} href='/request-appointment'>Learn More -<i className="arrow right"></i></a>
                    </a>
                    <a className="col-lg-4 mb-2" style={{minHeight: '444px', textDecoration: 'none' }} href='/what-we-offer'>
                        <img src={massage} alt="Professional massage therapy focusing on pain relief and recovery." className='servicesImg card-img-top' />
                        <div className="card-body mt-3 mb-1" style={{borderBottom: '1px solid #D2D2D2', height: '110px' }}>
                            <h2 style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 18, fontWeight: '700' }} >Massage Therapy</h2>
                            <p style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400' }} >Relax and relieve muscle tension with expert massage techniques that promote healing and overall well-being.</p>
                        </div>
                        <a style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 14, fontWeight: '600', textDecoration: 'none' }} href='/request-appointment'>Learn More -<i className="arrow right"></i></a>
                    </a>
                </div>
                <div className="row my-3">
                    <a className="col-lg-4 mb-2" style={{minHeight: '444px', textDecoration: 'none' }} href='/what-we-offer'>
                        <img src={active_care} alt="One-on-one training session to build endurance and achieve fitness goals." className='servicesImg card-img-top' />
                        <div className="card-body mt-3 mb-1" style={{borderBottom: '1px solid #D2D2D2', height: '110px' }}>
                            <h2 style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 18, fontWeight: '700' }} >Active Care</h2>
                            <p style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400' }} >Accelerate your athletic performance and recovery with specialized treatments for sports-related injuries.</p>
                        </div>
                        <a style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 14, fontWeight: '600', textDecoration: 'none' }} href='/request-appointment'>Learn More -<i className="arrow right"></i></a>
                    </a>
                    <a className="col-lg-4 mb-2" style={{minHeight: '444px', textDecoration: 'none' }} href='/what-we-offer'>
                        <img src={personal_training} alt="Physician conducting a personalized health assessment for tailored care plans." className='servicesImg card-img-top' />
                        <div className="card-body mt-3 mb-1" style={{borderBottom: '1px solid #D2D2D2', height: '110px' }}>
                            <h2 style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 18, fontWeight: '700' }} >Personal Training</h2>
                            <p style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400' }} >Engage in targeted exercises that build strength, flexibility, and resilience under professional guidance.</p>
                        </div>
                        <a style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 14, fontWeight: '600', textDecoration: 'none' }} href='/request-appointment'>Learn More -<i className="arrow right"></i></a>
                    </a>
                    <a className="col-lg-4 mb-2" style={{minHeight: '444px', textDecoration: 'none' }} href='/what-we-offer'>
                        <img src={medicine} alt="natasha" className='servicesImg card-img-top' />
                        <div className="card-body mt-3 mb-1" style={{borderBottom: '1px solid #D2D2D2', height: '110px' }}>
                            <h2 style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 18, fontWeight: '700' }} >Concierge Medicine</h2>
                            <p style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400' }} >Physician-Guided Care Plans: focusing on metabolic health, longevity, and heart & cardiovascular health.</p>
                        </div>
                        <a style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 14, fontWeight: '600', textDecoration: 'none' }} href='/request-appointment'>Learn More -<i className="arrow right"></i></a>
                    </a>
                </div>
                <div className='text-center'>
                    <a className="btn" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 246, background: '#BC9437', color: '#FFFFFF', fontSize: 16, fontFamily: 'Open Sans, sans-serif', fontWeight: '600', borderRadius: 0 }} href='/what-we-offer'>
                        View all services
                    </a>
                </div>
            </div>

            <div className='headerBox mt-5 mb-5'>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <video muted autoPlay loop preload="auto" poster={banner}
                        style={{
                            width: "80%",
                            objectFit: "cover"
                        }} >
                        <source src={AethosHome} type="video/mp4" />
                    </video>
                </div>
                <div style={{ width: '100%', textAlign: 'center' }}>
                    <h1 style={{ color: '#2E2E2E', fontSize: 28, fontFamily: 'Open Sans, sans-serif', fontWeight: '700', marginTop: 30 }}>One stop for all your health needs.</h1>
                </div>
                <div className='text-center mt-4' style={{ width: '100%'}}>
                    <a className="btn" style={{ display: 'inline-flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 246, background: '#BC9437', color: '#FFFFFF', fontSize: 16, fontFamily: 'Open Sans, sans-serif', fontWeight: '600', borderRadius: 0 }} href="https://aethos.janeapp.com">
                        Book a consultation
                    </a>
                </div>
            </div>

            <div className='schoolBox mt-5'>
                <div className="row">
                    <div className='col-sm-6'>
                        <img src={home_school_program} alt="Group of young athletes celebrating success with medals at a sports event." className='schoolImg' />
                    </div>
                    <div className='col-sm-6'>
                        <h3 className='mb-2' style={{ color: '#2E2E2E', fontSize: 28, fontFamily: 'Open Sans, sans-serif', fontWeight: '700' }}>Active Horizons Performance Program</h3>
                        <p className='mb-4' style={{ color: '#2E2E2E', fontSize: 16, fontFamily: 'Open Sans, sans-serif', fontWeight: '400' }}>At Aethos, our youth sports development programs are designed to help middle and high school athletes unlock their full potential. With a focus on physical literacy, confidence-building, and enhancing athletic performance, we offer customized training programs that foster long-term athletic success. Our expert coaches are committed to helping young athletes thrive in a competitive sports environment.</p>
                        <p className='mb-2' style={{ color: '#2E2E2E', fontSize: 20, fontFamily: 'Open Sans, sans-serif', fontWeight: '600' }}>Join the Aethos community and embrace a path towards lifelong fitness and sports excellence.</p>
                        <p style={{ color: '#2E2E2E', fontSize: 20, fontFamily: 'Open Sans, sans-serif', fontWeight: '600' }}>Contact us today to learn more about our youth athlete performance programs.</p>
                        <a className="btn" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: 48, width: 131, background: '#447B7F', color: '#FFFFFF', fontSize: 16, fontFamily: 'Open Sans, sans-serif', fontWeight: '600', borderRadius: 0 }} href="tel:+14165461511">
                            Contact us
                        </a>
                    </div>
                </div>
            </div>
            
            <div className='headerBox mt-5 mb-5'>
                <div style={{ textAlign: 'center' }}>
                    <h3 style={{ color: '#2E2E2E', fontSize: 28, fontFamily: 'Open Sans, sans-serif', fontWeight: '700' }}>What our customers have to say</h3>
                </div>
                <div className="row">
                    <div className="col-lg mt-4" style={{ marginRight: 20, maxHeight: '700px', padding: 0, boxShadow: '3px 3px 10px 0px rgba(33, 38, 50, 0.1), -3px -3px 10px 0px rgba(33, 38, 50, 0.05)', border: '1px solid rgba(228, 228, 228, 1)' }}>
                        <img src={natasha} alt="Personal training session featuring a client practicing exercises under their coach’s guidance. " style={{height: '240px', objectFit: 'cover', width: '100%'}} />
                        <div className="mt-2" style={{ height: '100px', marginLeft: 20, marginRight: 20 }}>
                            <p className="card-title" style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 20, fontWeight: '700' }}>Natasha Staniszewski</p>
                            <p className="card-text" style={{ color: '#808080', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '600' }}>CANADIAN SPORTS REPORTER</p>
                        </div>
                        <p className="card-text" style={{ marginBottom: 10, color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400', marginLeft: 20, marginRight: 20 }}>“Training at Aethos has been an incredibly positive experience. The team is welcoming, knowledgeable, and professional, always helping me tackle my fitness goals and challenges. The facility is fantastic with plenty of natural light and always kept spotlessly clean.”</p>
                    </div>
                    <div className="col-lg mt-4" style={{ marginRight: 20, maxHeight: '700px', padding: 0, boxShadow: '3px 3px 10px 0px rgba(33, 38, 50, 0.1), -3px -3px 10px 0px rgba(33, 38, 50, 0.05)', border: '1px solid rgba(228, 228, 228, 1)' }}>
                        <img src={paul} alt="Fitness client engaged in a one-on-one training session with a professional trainer." style={{height: '240px', objectFit: 'cover', width: '100%'}} />
                        <div className="mt-2" style={{ height: '100px', marginLeft: 20, marginRight: 20 }}>
                            <p className="card-title" style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 20, fontWeight: '700' }}>Paul barter</p>
                            <p className="card-text" style={{ color: '#808080', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '600' }}>TECH INVESTOR & CONSULTANT</p>
                        </div>
                        <p className="card-text" style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400', marginLeft: 20, marginRight: 20 }}>“Aethos is amazing! I’ve been regularly training with Emma, and her attention to detail with personalized feedback and corrective guidance makes a real difference between a good workout and a great one. I highly recommend Aethos to anyone serious about personal training.”</p>
                    </div>
                    <div className="col-lg mt-4" style={{ marginRight: 10, maxHeight: '700px', padding: 0, boxShadow: '3px 3px 10px 0px rgba(33, 38, 50, 0.1), -3px -3px 10px 0px rgba(33, 38, 50, 0.05)', border: '1px solid rgba(228, 228, 228, 1)' }}>
                        <img src={michaela} alt="Focused personal training session showcasing tailored exercises and coach support." style={{height: '240px', objectFit: 'cover', width: '100%'}} />
                        <div className="mt-2" style={{ height: '100px', marginLeft: 20, marginRight: 20 }}>
                            <p className="card-title" style={{ color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 20, fontWeight: '700' }}>Michaela Burns</p>
                            <p className="card-text" style={{ color: '#808080', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '600' }}>ENTREPRENEUR AND INTERIOR DESIGNER (OWNER MINT DECOR)</p>
                        </div>
                        <p className="card-text" style={{ marginBottom: 10, color: '#000000', fontFamily: 'Open Sans, sans-serif', fontSize: 16, fontWeight: '400', marginLeft: 20, marginRight: 20 }}>“I’m incredibly impressed with the holistic approach of the Aethos team. Having worked with many personal trainers, this is the first time I’ve experienced a training plan that is tailored to my specific strengths and weaknesses. I have deep respect for what Aethos is doing, and I can’t wait to tell others!”</p>                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomeComponent