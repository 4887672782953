import React from 'react'
import arrow from '../../images/home/arrow.png';
import '../styles/TeamMember.css';

function TeamMemberComponent({ item, idx }) {
    return (
        <>
            <div data-bs-toggle="modal" data-bs-target={`#exampleModal${idx}`} style={{ background: '#FDFDFD', boxShadow: '3px 3px 10px rgba(33, 38, 50, 0.10)', width: '330px', margin: '2%', cursor: 'pointer' }}>
                <img src={item.img} alt={item.name} maxWidth="330px" width="100%" />
                <div style={{ padding: '2%' }}>
                    <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Karla', fontWeight: '700', textTransform: 'uppercase' }}>{item.name}</div>
                    <div style={{ color: '#808080', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>{item.title}</div>
                </div>
            </div>

            <div className="modal fade" id={`exampleModal${idx}`} tabIndex="-1" aria-labelledby={`exampleModalLabel${idx}`} aria-hidden="true">
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header" style={{ display: 'flex', flexDirection: 'column', background: '#EFF3F5' }}>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            <div className='modalHeader'>
                                <img src={item.img} alt={item.name} style={{ maxWidth: "330px", width: "100%" }} />
                                <div className='memberText'>
                                    <h1 style={{ color: '#143142', fontSize: 30, fontFamily: 'Karla', fontWeight: '800', textTransform: 'uppercase' }}>{item.name}</h1>
                                    <br />
                                    <p style={{ color: 'black', fontSize: 24, fontFamily: 'Karla', fontWeight: '400', textTransform: 'uppercase' }}>{item.title}</p>
                                    <br />
                                    <a href="https://aethos.janeapp.com" target="_blank" rel="noopener noreferrer">
                                        <button style={{ background: '#143141', borderRadius: 24, color: 'white' }} type="button" className="btn">Book Now <span><img src={arrow} alt='arrow' /></span></button>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="modal-body" style={{ padding: '5% 5% 2% 5%' }}>
                            <div style={{ color: 'black', fontSize: 16, fontFamily: 'Karla', fontWeight: '500' }}>
                                {item.description.split('\n').map((line, index) => <div key={index} style={{ paddingBottom: '1.5%' }}>{line}</div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TeamMemberComponent