import React from 'react'
import banner from '../../images/whatisaethos/banner.png';
import yoga from '../../images/whatisaethos/yoga_position.png';
import fullBody from '../../images/whatisaethos/full_body.png';
import personalTraining from '../../images/whatisaethos/personal_training.png';
import handsonTherapy from '../../images/whatisaethos/handson_therapy.png';

import '../styles/WhatIsAethos.css';

function WhatIsAethosComponent() {
    return (
        <div style={{ paddingTop: '5vh' }}>
            <div style={{ position: 'relative' }}>
                <img src={banner} style={{ objectFit: 'cover', width: '100%' }} alt='What is aethos banner' />
                <div className='bannerText'>THE AETHOS EXPERIENCE</div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', padding: '5%' }}>
                <img src={yoga} alt='yoga' width="264px" />
                <div className='yogatext'>
                    <h1 style={{ color: '#172026', fontSize: 46, fontFamily: 'Rubik', fontWeight: '400', letterSpacing: 0.46 }}>What is Aethos?</h1>
                    <p style={{ color: 'black', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', letterSpacing: 0.32 }}>Aethos is a luxury health facility in Uptown Toronto that has a team of professionals to take care of all your fitness needs. <br />
                        Our full-body treatment, movement and goal setting approach helps identify your personalized programming needs to optimize your physical self. We have a collaborative team to maximize your time and a results-based approach to achieve your health and fitness goals.</p>
                </div>
            </div>

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexWrap: 'wrap', padding: '0 2%' }}>
                <div className='boxes'>
                    <div>
                        <span style={{ color: 'black', fontSize: 42, fontFamily: 'Rubik', fontWeight: '500' }}>
                            83,825 + <br />
                        </span>
                        <span style={{ color: '#9B9B9B', fontSize: 18, fontFamily: 'Rubik', fontWeight: '400' }}>
                            Appointments
                        </span>
                    </div>
                </div>
                <div className='boxes'>
                    <div>
                        <span style={{ color: 'black', fontSize: 42, fontFamily: 'Rubik', fontWeight: '500' }}>
                            1754 + <br />
                        </span>
                        <span style={{ color: '#9B9B9B', fontSize: 18, fontFamily: 'Rubik', fontWeight: '400' }}>
                            Members
                        </span>
                    </div>
                </div>
                <div className='boxes'>
                    <div>
                        <span style={{ color: 'black', fontSize: 42, fontFamily: 'Rubik', fontWeight: '500' }}>
                            147 + <br />
                        </span>
                        <span style={{ color: '#9B9B9B', fontSize: 18, fontFamily: 'Rubik', fontWeight: '400' }}>
                            5 Star Google Reviews
                        </span>
                    </div>
                </div>
                {/* <div className='boxes'>
                    <div>
                        <span style={{ color: 'black', fontSize: 42, fontFamily: 'Rubik', fontWeight: '500' }}>
                            12 + <br />
                        </span>
                        <span style={{ color: '#9B9B9B', fontSize: 18, fontFamily: 'Rubik', fontWeight: '400' }}>
                            Awards since 2020
                        </span>
                    </div>
                </div> */}
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap-reverse', justifyContent: 'space-evenly', alignItems: 'center', padding: '5%' }}>
                <div className='servicetext'>
                    <h1 style={{ fontSize: 44, fontFamily: 'Rubik', fontWeight: '500' }}>Full-body Assessment</h1>
                    <p style={{ color: 'black', fontSize: 18, fontFamily: 'Karla', fontWeight: '400', paddingTop: '5%' }}>Your first session begins with a Full-Body Assessment that looks at how you move. The Assessment helps us identify the proper plan of action a set you on the appropriate treatment and/or training plan. <br /><br />
                        <span style={{ fontSize: 24, fontWeight: 500 }}>
                            The goal is to get you back to doing what you love!
                        </span>
                    </p>
                </div>
                <img src={fullBody} alt='full body' style={{ maxWidth: '100%' }} />
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', padding: '5%' }}>
                <img src={personalTraining} alt='personal training' style={{ maxWidth: '100%' }} />
                <div className='servicetext'>
                    <h1 style={{ fontSize: 44, fontFamily: 'Rubik', fontWeight: '500' }}>Personal Training Session</h1>
                    <p style={{ color: 'black', fontSize: 18, fontFamily: 'Karla', fontWeight: '400', paddingTop: '5%' }}>Our Professional Coaches collaborate with our clinicians to design and execute a tailored program that will get you to your fitness goals. Lose weight, build strength, improve flexibility, feel like a champion. <br /><br />
                        <span style={{ fontSize: 24, fontWeight: 500 }}>
                            You are capable of achieving great things, and we will show you the way.
                        </span>
                    </p>
                </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap-reverse', justifyContent: 'space-evenly', alignItems: 'center', padding: '5%' }}>
                <div className='servicetext'>
                    <h1 style={{ fontSize: 44, fontFamily: 'Rubik', fontWeight: '500' }}>Hands on Therapy Session</h1>
                    <p style={{ color: 'black', fontSize: 18, fontFamily: 'Karla', fontWeight: '400', paddingTop: '5%' }}>Hands on therapy can range from soft tissue, manipulation, joint mobility and more. Recovery sessions will be built  into your personalized program based on your health goals and needs. Recovery is a vital part of treatment to aid in alleviating pain, injury prevention and your overall well-being.
                        <br /><br /></p>
                </div>
                <img src={handsonTherapy} alt='hands on therapy' style={{ maxWidth: '100%' }} />
            </div>
        </div>
    )
}

export default WhatIsAethosComponent;