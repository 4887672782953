import React from 'react'
import '../styles/HealthSafety.css';
import healthBanner from '../../images/HealthSafety/healthBanner.png';
import signage from '../../images/HealthSafety/signage.png';
import healthImage1 from '../../images/HealthSafety/Health1.svg';
import healthImage2 from '../../images/HealthSafety/Health2.svg';
import healthImage3 from '../../images/HealthSafety/Health3.svg';
import sanitizer from '../../images/HealthSafety/sanitizer.png';
import enterRight from '../../images/HealthSafety/enterRight.png';
import mask from '../../images/HealthSafety/mask.png';
import shirt from '../../images/HealthSafety/shirt.png';
import checkin from '../../images/HealthSafety/checkin.png';
import waitin from '../../images/HealthSafety/waitin.png';
import shoes from '../../images/HealthSafety/shoes.png';
import exit from '../../images/HealthSafety/exit.png';
import bin from '../../images/HealthSafety/bin.png';
import sun from '../../images/HealthSafety/sun.png';

function HealthSafetyComponent() {
    return (
        <div style={{ paddingTop: '5vh' }}>
            <div style={{ position: 'relative' }}>
                <img src={healthBanner} style={{ objectFit: 'cover', width: '100%' }} alt='Health and Safety banner' />
                <div className='healthbannerText'>How we are keeping you safe</div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', padding: '5%' }}>
                <img src={signage} alt='signage' width="255px" height="226px" />
                <div className='signagetext'>
                    <h1 style={{ color: '#172026', fontSize: 46, fontFamily: 'Rubik', fontWeight: '400', letterSpacing: 0.46 }}>Our Facility Signage</h1>
                    <p style={{ color: 'black', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', letterSpacing: 0.32 }}>To keep our clients, staff and community safe, we have created special arrival and departure procedures for our members. <br />
                        As of June 8th, 2020 we have updated our health and safety policies to better protect our members and communities. Please view our procedures below to understand the new facility flow. Throughout our location, you will find signage to help direct you and act as visual markers for where to stand, sit, walk and sanitize. These are some of the many ways we are keeping you safe during your visits at Aethos.</p>
                </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', padding: '5%' }}>
                <img src={healthImage1} style={{ maxWidth: '366px', width: '100%' }} alt='Health and Safety 1' />
                <img src={healthImage2} style={{ maxWidth: '366px', width: '100%' }} alt='Health and Safety 2' />
                <img src={healthImage3} style={{ maxWidth: '366px', width: '100%' }} alt='Health and Safety 3' />
            </div>

            <div style={{ padding: '0% 7%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <div style={{ color: 'black', fontSize: 44, fontFamily: 'Rubik', fontWeight: '500', letterSpacing: 0.88, padding: '2% 0%' }}>Before your Visit</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px' }}>
                        <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48 }}>
                            BEFORE APPOINTMENT
                        </div>
                        <br />
                        <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                            Please ensure you are feeling well prior to visiting Aethos. We ask that all members do a self screen before entering our facility so we may continue to keep both our Members and team safe.
                        </div>
                    </div>
                    <div style={{ color: 'black', maxWidth: '490px' }}>
                        <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48 }}>
                            ON ARRIVAL
                        </div>
                        <br />
                        <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                            Please check in with our front desk. You will be assigned a locker and given slippers if needed.
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ padding: '3% 7%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <div style={{ color: 'black', fontSize: 44, fontFamily: 'Rubik', fontWeight: '500', letterSpacing: 0.88, padding: '2% 0%' }}>Check- In Procedure</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={sanitizer} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='sanitizer'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Sanitize Hands & Shoes
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Upon entering the facility, please sanitize your hands and the bottom of your shoes using our sanitation station.
                            </div>
                        </div>
                    </div>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={enterRight} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='enterRight'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Enter to Right
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                The facility has been lined with arrows to direct traffic. Please follow the lines when going in and out of the facility.
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={mask} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='mask'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Place Mask on Face
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Make sure to cover both your nose and mouth. Reusable masks are available for purchase as well as disposable gloves.
                            </div>
                        </div>
                    </div>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={shirt} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='shirt'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Change for Appointment
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Use your assigned locker to prepare for your appointment. Lockers are staggered to maintain social distancing practices.
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={checkin} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='checkin'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Check In at Front Desk
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Check-in at the front to receive your assigned locker number and assigned seat number for your session.
                            </div>
                        </div>
                    </div>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={waitin} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='waitin'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Wait in Assigned Seat
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Please wait in your assigned seat until your trainer or practitioner is ready to see you.
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={shoes} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='shoes'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Shoe Exchange
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                We provide slippers to change into if needed.
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ padding: '3% 7% 6% 7%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
                <div style={{ color: 'black', fontSize: 44, fontFamily: 'Rubik', fontWeight: '500', letterSpacing: 0.88, padding: '2% 0%' }}>Leaving the Facility</div>
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={sanitizer} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='sanitizer'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Wash & Sanitize Hands
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Thoroughly wash and sanitize your hands after your session in our change rooms.
                            </div>
                        </div>
                    </div>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={exit} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='exit'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Exit the Facility
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Avoid surface contact by using your elbow to push the front door exit button.
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={enterRight} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='exitright'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Exit to the Right
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Follow the lines on the ground to exit the gym floor and enter into the front reception.
                            </div>
                        </div>
                    </div>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={mask} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='mask'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Keep Mask On
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                For safety purposes, make sure your mask remains on until you leave the building.
                            </div>
                        </div>
                    </div>
                </div>
                <br />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'flex-start', width: '100%' }}>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={bin} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='bin'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Drop Sandals in Bin
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                Leave your sandals at the front reception so that we can sanitize them for the next member.
                            </div>
                        </div>
                    </div>
                    <div style={{ color: 'black', maxWidth: '490px', display: 'flex', alignItems: 'flex-start' }}>
                        <img src={sun} width="64px" style={{ paddingRight: '5%', paddingTop: '2%' }} alt='sun'></img>
                        <div>
                            <div style={{ fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48, paddingBottom: '1%' }}>
                                Enjoy the Rest of Your Day
                            </div>
                            <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>
                                We thank you for your time at the clinic and hope to see you again soon!
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div style={{ textAlign: 'center', color: '#172026', fontSize: 46, fontFamily: 'Rubik', fontWeight: '400', letterSpacing: 0.46 }}>Sanitation Procedures</div>
                <div className='procedureContainer'>
                    <div className='procedureBox'>
                        <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase', letterSpacing: 3 }}>Hand washing</div>
                        <div style={{ color: '#0D0D0D', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>Practitioners will thoroughly wash their hands for at least 20 seconds with warm water and soap. Hand sanitizer will be applied afterwards.</div>
                    </div>
                    <div className='procedureBox'>
                        <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase', letterSpacing: 3 }}>Equipment Station</div>
                        <div style={{ color: '#0D0D0D', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>Practitioner will immediately clean any area used by the client with disinfectant wipes or sprays. This includes any equipment, treatment tables, door handles, and any other item used.</div>
                    </div>
                    <div className='procedureBox'>
                        <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase', letterSpacing: 3 }}>Change Clothes</div>
                        <div style={{ color: '#0D0D0D', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>Practitioners are required to wear their clean Aethos attire and indoor shoes during their shift. They must change out of those clothes at the end of their shift and wash them when they return home.</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default HealthSafetyComponent