import React from 'react'
import AethosIcon from '../images/AethosIcon.png';
import aethosLogo from '../images/aethosLogo.png';
import linkedin from '../images/linkedin.png';
import insta from '../images/insta.png';
import facebook from '../images/facebook.png';
import youtube from '../images/youtube.png';
import './styles/Footer.css';
import { Link } from 'react-router-dom';

function FooterComponent() {
    const today = new Date();

    return (
        <div style={{ position: 'relative', backgroundColor: '#202020', display: 'flex', flexDirection: 'column',rowGap: '50px' }}>
            
            <div className='requestBox py-4'>
                <div className='col-md-10'>
                    <h2 style={{ color: '#2E2E2E', fontSize: 28, fontFamily: 'Rubik', fontWeight: '700' }}>Are you ready to get started?</h2>
                    <h2 style={{ color: '#7A7A7A', fontFamily: 'Rubik', fontSize: 16, fontWeight: '600' }}>Request an appointment today and get a consultation from one of our experts.</h2>
                </div>
                <div className='col-md-2' style={{ display: 'flex', alignItems: 'center' }}>
                    <a className="btn" style={{ height: 48, width: 246, background: '#BC9437', color: '#FFFFFF', fontSize: 16, fontFamily: 'Rubik', fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 0 }} href='/request-appointment'>
                        Request appointment
                    </a>
                </div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', paddingLeft: '8%', paddingRight: '8%', justifyContent: 'space-between' }}>
                <div className='addresscolumn' style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                        <img src={AethosIcon} width="26%" style={{ marginRight: '3%' }} alt='Aethos Sports Medical Clinic.'></img>
                        <img src={aethosLogo} width="50%" alt='Aethos Sports Medical Clinic.'></img>
                    </div>
                    <div className='mt-4'>
                        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <a style={{ color: '#898989', textDecoration: 'none' }} href='https://maps.app.goo.gl/Wf1LVN3QmBhCfcrQ7'>41 Scarsdale Rd, Unit 2,<br></br>Toronto, Ontario, CA</a>
                        </div>
                        <div className='mt-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <a style={{ color: '#898989', textDecoration: 'none' }} href="tel:+14165461511">+1 416-546-1511</a>
                        </div>
                        <div className='mt-3' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <a style={{ color: '#898989', textDecoration: 'none' }} href="mailto:team@aethos.ca">team@aethos.ca</a>
                        </div>
                    </div>
                    <div className='mt-4' style={{ display: 'flex' }}>
                        <a href="https://www.linkedin.com/company/aethos-human-performance-company/" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }} >
                            <img src={linkedin} width="32px" height="32px" alt='linkedin' />
                        </a>
                        <a href="https://www.instagram.com/teamaethos/" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }}>
                            <img src={insta} width="32px" height="32px" alt='instagram' />
                        </a>
                        <a href="https://www.facebook.com/teamaethos/" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }}>
                            <img src={facebook} width="32px" height="32px" alt='facebook' />
                        </a>
                        <a href="https://www.youtube.com/channel/UC_0zk93cVVaH5UFiVmfBswQ" target='_blank' rel="noreferrer" style={{ marginRight: '5%' }}>
                            <img src={youtube} width="32px" height="32px" alt='youtube' />
                        </a>
                    </div>
                </div>

                <div className='footerlinks' style={{ minWidth: '15%' }}>
                    <Link className="footerLinkItemBold mb-4" to="/what-we-offer">
                        What we offer
                    </Link>
                    <Link className="footerLinkItem mb-4" to="/what-we-offer">
                        Chiropractic Care
                    </Link>
                    <Link className="footerLinkItem mb-4" to="/what-we-offer">
                        Physiotherapy
                    </Link>
                    <Link className="footerLinkItem mb-4" to="/what-we-offer">
                        Massage Therapy
                    </Link>
                    <Link className="footerLinkItem" to="/what-we-offer">
                        Naturopathic Medicine
                    </Link>
                </div>

                <div className='footerlinks' style={{ minWidth: '15%' }}>
                    <div className="footerLinkItemBold mb-5"></div>
                    <Link className="footerLinkItem mb-4" to="/what-we-offer">
                        Dietitian
                    </Link>
                    <Link className="footerLinkItem mb-4" to="/what-we-offer">
                        Athletic Therapy
                    </Link>
                    <Link className="footerLinkItem mb-4" to="/what-we-offer">
                        Active Care
                    </Link>
                    <Link className="footerLinkItem" to="/what-we-offer">
                        Concierge Medicine
                    </Link>
                </div>

                <div className='footerlinksPart1' style={{ minWidth: '17%' }}>
                    <Link className="footerLinkItemBold mb-4" to="contact-us">
                        Contact Us
                    </Link>
                    <Link className="footerLinkItemBold mb-4" to="contact-us">
                        Partner with us
                    </Link>
                    <Link className="footerLinkItemBold mb-4" to="/health-safety">
                        Health & Safety
                    </Link>
                    <Link className="footerLinkItemBold" to="contact-us">
                        Careers: Join Aethos
                    </Link>
                </div>

                <div className='footerlinksPart2 mt-5' style={{ minWidth: '22%'}}>
                    <form action="https://aethos.us20.list-manage.com/subscribe/post?u=acfcbf105fef9ce675c593c8a&amp;id=301cd63e7a" method="post" id="form3" name="mc-embedded-subscribe-form" target="_blank" >
                        <label className='mb-3' style={{ color: 'white', fontFamily: 'Karla', fontSize: 16 }} for="mce-EMAIL"> Sign up for Email Updates </label><br></br>
                        <div style={{ display: 'flex' }}>
                            <input className="emailInput" type="email" name="EMAIL" id="mce-EMAIL" placeholder="Enter your Email" required style={{backgroundColor: 'white'}} />
                            <input className="joinButton" type="submit" value="Sign Up" name="subscribe" id="mc-embedded-subscribe" style={{ height: 40, width: 107, background: '#BC9437', color: '#FFFFFF', fontSize: 16, fontWeight: '600', display: 'flex', alignItems: 'center', justifyContent: 'center', borderRadius: 0, border: 0 }} />
                        </div>
                    </form>
                </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', alignItems: 'center', paddingTop: 20, paddingBottom: 20, marginLeft: '8%', marginRight: '8%', borderTop: '1px #8B8B8B solid' }}>
                <div className='py-md-1 py-3' style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    <a style={{ color: 'white', fontFamily: 'Karla', fontSize: 14, marginBottom: 0, textDecoration: 'none' }} href='/terms-of-service'>Terms of Serivce</a>
                    <a style={{ color: 'white', fontFamily: 'Karla', fontSize: 14, marginBottom: 0, marginLeft: 50, textDecoration: 'none' }} href='/privacy-policy'>Privacy Policy</a>
                </div>
                <div className='py-md-1 py-3' style={{ display: 'flex' }}>
                    <p style={{ color: 'white', fontFamily: 'Karla', fontSize: 14, textAlign: 'center', marginBottom: 0 }}>© Aethos {today.getFullYear()}. All rights reserved.</p>
                </div>
            </div>
        </div>
    )
}

export default FooterComponent