import React from 'react'
import { NavLink } from "react-router-dom";
import './styles/Navbar.css'
import aethosLogo from '../images/aethosLogo.png';

function NavbarComponent() {
    return (
        <nav className="navbar navbar-expand-lg bg-body-tertiary fixed-top">
            <div className="container-fluid ">
                <a className="navbar-brand" href="/" style={{ fontFamily: 'Rubik', color: '#BC9437', fontWeight: 500, fontSize: '30px', letterSpacing: 6.48, padding: '0 0 0 5%' }}>
                    <img src={aethosLogo} width="50%" alt='Aethos Sports Medical Clinic.'></img>
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav mb-2 mb-lg-0" style={{ display: 'flex', justifyContent: 'space-evenly', width: '100%', fontFamily: 'Rubik' }}>
                        <li className="nav-item"></li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/what-we-offer'>Services</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/team'>The team</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/what-is-aethos'>About us</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to='/contact-us'>Contact us</NavLink>
                        </li>
                        <a className="btn" style={{ color: '#9C7E36', textSize: '16px', textWeight: '400' }} href="https://aethos.janeapp.com" target="_blank" rel="noreferrer">
                            Book a consultation
                        </a>
                        <a className="btn" style={{ border: '2px solid #BC9437', borderRadius: 0, color: '#BC9437', textSize: '16px', textWeight: '400' }} href="tel:+14165461511" target="_blank" rel="noreferrer">
                            +1 416-546-1511
                        </a>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default NavbarComponent