import React from 'react';
import teamBanner from '../../images/team/teamBanner.png';
import Recruitment from '../../images/team/Recruitment.mp4';
import winners from '../../images/team/winners.png';
import { teamCards } from './teamCards';
import '../styles/Team.css';
import TeamMemberComponent from './TeamMember.component';

function TeamComponent() {
    return (
        <div>
            <div style={{ position: 'relative' }}>
                <video autoPlay loop preload="auto" poster={teamBanner} controls controlslist="nodownload nofullscreen noremoteplayback noplaybackrate foobar"
                        style={{
                            width: "100%",
                            objectFit: "cover",
                            paddingTop: '4%'
                        }} >
                        <source src={Recruitment} type="video/mp4" />
                    </video>
                <div className='bannerText'>MEET THE TEAM</div>
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-evenly', alignItems: 'center', padding: '5%' }}>
                <img src={winners} alt='winners' width="434px" style={{ maxWidth: '100%' }} />
                <div className='winnerstext'>
                    <h1 style={{ color: '#172026', fontSize: 46, fontFamily: 'Rubik', fontWeight: '400' }}>Elite Athletes.<br />Expert Trainers.</h1>
                    <p style={{ color: 'black', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>Meet our team of experienced health professionals who will help you achieve peak health and performance. <br /><br />
                        <span style={{ fontWeight: 500 }}>Come on board with our team!.</span></p>
                    <a style={{ background: '#D8AB46', borderRadius: 24, color: 'white', padding: '1% 5%', fontFamily: 'Karla', fontWeight: 500 }} className="btn" href='/contact-us'><nobr>Apply Now</nobr></a>
                </div>
            </div>

            <div className="teamContainer">
                {teamCards.map((item, index) => {
                    return (
                        <TeamMemberComponent item={item} idx={index} key={index} />
                    );
                })}
            </div>
        </div>
    )
}

export default TeamComponent;