import React from 'react'
import '../styles/WhatWeOffer.css';

function WhatWeOfferComponent() {
    return (
        <div style={{ paddingTop: '15vh' }}>
            <div style={{ textAlign: 'center', color: '#143142', fontSize: 40, fontFamily: 'Rubik', fontWeight: '500' }}>WHAT WE OFFER</div>

            <div className='offerContainer'>
                <div className='offerBox'>
                    <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase' }}>Personal Training</div>
                    <div style={{ width: '100%', color: '#0D0D0D', fontSize: 14, fontFamily: 'Karla', fontWeight: '400' }}>Our professional coaches work with you to design and execute a personalized program that will get you to your fitness goals. Lose weight, build strength, improve flexibility, feel like a champion. You are capable of achieving great things, and we will show you the way.Lose weight, build muscle, gain confidence, reduce stress, and feel your best! </div>
                    <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400', paddingTop: '15%' }}>
                        <div>Baseline Testing</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Programming</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Weight Loss</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Performance</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Skill Development</div>
                    </div>
                </div>
                <div className='offerBox'>
                    <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase' }}>PHYSIOTHERAPY</div>
                    <div style={{ width: '100%', color: '#0D0D0D', fontSize: 14, fontFamily: 'Karla', fontWeight: '400' }}>Our physiotherapy services can help with the following symptoms: Sports injuries, Shoulder paiN, Neck pain, Hip pain, Knee pain, Stiffness or pain from prolonged sitting, Upper, middle, and lower back pain, Bursitis, Headaches & migraines, Tennis/Golfer's elbow, Carpal Tunnel Syndrome, Shin splits. </div>
                    <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400', paddingTop: '15%' }}>
                        <div>Dry needling</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Acupuncture</div>
                        <hr  style={{ marginTop: 5 }}/>
                        <div>Joint Mobilization</div>
                        <hr  style={{ marginTop: 5 }}/>
                        <div>Myofascial Techniques</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Corrective Exercise</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Active Care Programming</div>
                    </div>
                </div>
                <div className='offerBox'>
                    <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase' }}>Chiropractic</div>
                    <div style={{ width: '100%', color: '#0D0D0D', fontSize: 14, fontFamily: 'Karla', fontWeight: '400' }}>Our chiropractic services can help with the following symptoms: Sports injuries, Shoulder pain, Neck pain, Hip pain, Knee pain, Stiffness or pain from prolonged sitting, Upper, middle, and lower back pain, Bursitis, Headaches & migraines, Tennis/Golfer's elbow, Carpal Tunnel Syndrome, Shin splits. </div>
                    <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400', paddingTop: '15%' }}>
                        <div>Dry needling</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Acupuncture</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Joint Mobilization</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Joint Adjustments</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Myofascial Techniques</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Corrective Exercise</div>
                    </div>
                </div>
                <div className='offerBox'>
                    <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase' }}>Massage Therapy</div>
                    <div style={{ width: '100%', color: '#0D0D0D', fontSize: 14, fontFamily: 'Karla', fontWeight: '400' }}>Through multi-disciplinary treatment techniques and state-of-the-art technology, our practitioners build recovery into your personalized program. Alleviate pain, recover from and prevent injury, take care of yourself. You’re in good hands. Massage therapy helps alleviate muscle tension, poor circulation, excessive stress, joint mobility & flexibility, soft tissue injuries, chronic back pain, insomnia, high blood pressure, delayed onset muscle soreness and anxiety. </div>
                    <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400', paddingTop: '15%' }}>
                        <div>Baseline Testing</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Programming</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Weight Loss</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Performance</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Skill Development</div>
                    </div>
                </div>
                <div className='offerBox'>
                    <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase' }}>Dietetics Naturopathic Medicine</div>
                    <div style={{ width: '100%', color: '#0D0D0D', fontSize: 14, fontFamily: 'Karla', fontWeight: '400' }}>The naturopathic philosophy is to stimulate the healing power of the body and treat the underlying cause of disease. Symptoms of disease are seen as warning signals of improper functioning of the body, and unfavourable lifestyle habits. Naturopathic Medicine emphasizes disease as a process rather than as an entity. </div>
                    <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400', paddingTop: '15%' }}>
                        <div>Nutrition plans</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Supplementation</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Blood work</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Testing (Dutch & DNA testing)</div>
                    </div>
                </div>
                <div className='offerBox'>
                    <div style={{ color: '#143141', fontSize: 20, fontFamily: 'Rubik', fontWeight: '600', textTransform: 'uppercase' }}>Registered Nurses</div>
                    <div style={{ width: '100%', color: '#0D0D0D', fontSize: 14, fontFamily: 'Karla', fontWeight: '400' }}>Our registered nurse offers various services and therapeutics to keep you feeling refreshed and at your best. Our aesthetic services can aid in relief and provide a confidence boost: helping with facial wrinkles, skin rejuvenation, thinning hair, hyperhidrosis, migraines, jaw clenching/bruxism, stubborn fat, and vitamin absorption. </div>
                    <div style={{ fontSize: 16, fontFamily: 'Karla', fontWeight: '400', paddingTop: '15%' }}>
                        <div>IV vitamin drips</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Facial Microneedling with PRP</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Hair Restoration with PRP</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Neurotoxins (Botox, Xeomin)</div>
                        <hr style={{ marginTop: 5 }} />
                        <div>Lipo Dissolve</div>
                    </div>
                </div>
            </div>

            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', paddingBottom: '5vw' }}>
                <div className='wwofaq'>
                    <div className='faqtext'>
                        <div style={{ color: '#F8FEFF', fontSize: 22, fontFamily: 'Karla', fontWeight: '700' }}>How do I choose which therapist is right for me?</div><br />
                        <div style={{ color: '#D8D8D8', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>Our therapists specialize in specific injuries, sports, and have various treatment certifications. You can either choose a therapist based on the time that's convenient for you or read through each therapist's profile to find someone who has the requirements you need.</div>
                        <br />
                        <div style={{ color: '#F8FEFF', fontSize: 22, fontFamily: 'Karla', fontWeight: '700' }}>Can I use my health insurance plan?</div><br />
                        <div style={{ color: '#D8D8D8', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>Yes, our therapists are licensed physiotherapists, chiropractors, and registered massage therapists. Please check with your insurance provider to see if a doctor's referral letter is required before you book your session to ensure you are able to claim.</div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default WhatWeOfferComponent;