import React, { useRef, useState } from 'react';
import Contactbanner from '../../images/contactus/Contactbanner.png';
import arrow from '../../images/home/arrow.png';
import location from '../../images/contactus/location.png';
import call from '../../images/contactus/call.png';
import email from '../../images/contactus/email.png';

import '../styles/Contactus.css';
import emailjs from '@emailjs/browser';
import Popup from '../../shared/Popup';
import ReCAPTCHA from "react-google-recaptcha";

function ContactusComponent() {

    const [buttonPopup, setButtonPopup] = useState(false);
    const [capVal, setCapVal] = useState(null);
    const recaptchaRef = useRef(null);

    function sendEmail(e) {
        e.preventDefault();

        emailjs.sendForm('service_7rdbwzn', 'template_3rfgk1o', e.target, 'user_aAx63STK8JFSdqbKPIGOv')
            .then((result) => {
                console.log(result.text);
                setButtonPopup(true);
            }, (error) => {
                console.log(error.text);
            });
        e.target.reset();
        // Reset reCAPTCHA
        if (recaptchaRef.current) {
            recaptchaRef.current.reset();
        }
    }

    return (
        <div style={{ paddingTop: '5vh' }}>
            <div style={{ position: 'relative' }}>
                <img src={Contactbanner} style={{ objectFit: 'cover', width: '100%' }} alt='Contact Us banner' />
                <div className="hero-textcontact">
                    <h1 className="titlecontact">
                        We're <br />
                        Here<br />
                        For You!
                    </h1>
                </div>
            </div>
            <div className='formContainer'>
                <div className='formHeading'>Ask us anything</div>
                <div className='formText'>
                    Our team will personally get in touch with you during our business hours to discuss any questions or requests that you may have.
                </div>
                <br />
                <form onSubmit={sendEmail} id="form1">
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'white', fontFamily: 'Karla', fontSize: 14 }}> First Name <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="firstName input-text input-row1"
                                type="text"
                                name="First"
                                required
                                style={{ width: '280px', background: 'rgba(4.25, 4.25, 4.25, 0.10)', borderRadius: 5, border: '1px #A7A7A7 solid', color: 'white', padding: '1%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'white', fontFamily: 'Karla', fontSize: 14 }}> Last Name </label>
                            <input
                                className="lastName input-text input-row1"
                                type="text"
                                name="Last"
                                style={{ width: '280px', background: 'rgba(4.25, 4.25, 4.25, 0.10)', borderRadius: 5, border: '1px #A7A7A7 solid', color: 'white', padding: '1%' }}
                            />
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'white', fontFamily: 'Karla', fontSize: 14 }}> Email Id <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="Email input-text"
                                type="email"
                                name="Email"
                                required
                                style={{ width: '280px', background: 'rgba(4.25, 4.25, 4.25, 0.10)', borderRadius: 5, border: '1px #A7A7A7 solid', color: 'white', padding: '1%' }}
                            />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <label style={{ color: 'white', fontFamily: 'Karla', fontSize: 14 }}> Phone Number <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                            <input
                                className="phno input-text"
                                type="tel"
                                name="Phone"
                                required
                                style={{ width: '280px', background: 'rgba(4.25, 4.25, 4.25, 0.10)', borderRadius: 5, border: '1px #A7A7A7 solid', color: 'white', padding: '1%' }}
                            />
                        </div>
                    </div>
                    <br />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <label style={{ color: 'white', fontFamily: 'Karla', fontSize: 14 }}> Inquiry <span style={{ color: '#FF0000', fontSize: 16, fontFamily: 'Karla', fontWeight: '400' }}>*</span></label>
                        <textarea
                            className="message input-text"
                            type="text"
                            name="Message"
                            // defaultValue={props.match.params.id}
                            required
                            style={{ background: 'rgba(4.25, 4.25, 4.25, 0.10)', borderRadius: 5, border: '1px #A7A7A7 solid', color: 'white', padding: '1%' }}
                        />
                    </div>
                    <br />
                    <ReCAPTCHA
                        theme='dark'
                        ref={recaptchaRef}
                        sitekey="6LciBywpAAAAADaQ-LhY931ukbQ6yD4KaiNVfZ0x"
                        onChange={(val) => setCapVal(val)}
                    />
                    <br />
                    <button disabled={!capVal} style={{ background: '#D8AB46', borderRadius: 24, display: 'flex', alignItems: 'center', width: '150px', color: 'white', justifyContent: 'center' }} className="btn" type='submit'>
                        Submit
                        <img src={arrow} style={{ height: 'auto', width: '14px', marginLeft: '5%' }} alt='arrow' />
                    </button>
                </form>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly', paddingTop: '10%', flexWrap: 'wrap', alignItems: 'center' }}>
                <div className='contactInfoContainer'>
                    <div style={{ color: 'black', fontSize: 44, fontFamily: 'Rubik', fontWeight: '500', letterSpacing: 0.88 }}>Contact Information</div>
                    <br />
                    <div style={{ display: 'flex', paddingBottom: '3%' }}>
                        <img src={location} alt='location' />
                        <div style={{ color: '#535353', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', textDecoration: 'underline', paddingLeft: '5%' }}>41 Scarsdale Rd, Unit 2, Toronto</div>
                    </div>
                    <div style={{ display: 'flex', paddingBottom: '3%' }}>
                        <img src={call} alt='call' />
                        <div style={{ color: '#535353', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', textDecoration: 'underline', paddingLeft: '5%' }}>416-546-1511</div>
                    </div>
                    <div style={{ display: 'flex', paddingBottom: '3%' }}>
                        <img src={email} alt='email' />
                        <div style={{ color: '#535353', fontSize: 16, fontFamily: 'Karla', fontWeight: '400', textDecoration: 'underline', paddingLeft: '5%' }}>team@aethos.ca</div>
                    </div>
                    <br />
                    <div style={{ color: 'black', fontSize: 24, fontFamily: 'Karla', fontWeight: '500', letterSpacing: 0.48 }}>Working Hours</div>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px #D2D2D2 solid', padding: '2%' }}>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>Monday to Thursday</div>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>6:00 AM - 8:00 PM</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px #D2D2D2 solid', padding: '2%' }}>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>Friday</div>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>6:00 AM - 6:00 PM</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', borderBottom: '1px #D2D2D2 solid', padding: '2%' }}>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>Saturday</div>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>7:00 AM - 4:00 PM</div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '2%' }}>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>Sunday</div>
                        <div style={{ color: '#535353', fontSize: 18, fontFamily: 'Karla', fontWeight: '400' }}>Closed</div>
                    </div>
                </div>
                <iframe className='mapContainer' title="Contact map" width="100%" height="620" frameborder="0" allowfullscreen="" aria-hidden="false" tabindex="0" src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJbRk1bpXN1IkRmKMPfcgGa30&key=AIzaSyCTjrlYcz2er1Xh1WKyjqqnUPIGEMcIodY"></iframe>
            </div>
            <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
                <h3>Message sent successfully!</h3>
                <p>Team Aethos will get back to you soon.</p>
            </Popup>
        </div>
    )
}

export default ContactusComponent;